// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
//TODO MIGUEL: Find out how to keep state inside this typescript file... to be able to set the counter
const CounterGts = (props: PageProps) => (
  <Layout>
    <SEO title="Counter TypeScript" />
    <h1>Counter TypeScript</h1>
    <p>Welcome to counter page ({props.path})</p>
    <div>
      <p>Current count: 0</p>
    </div>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default CounterGts
